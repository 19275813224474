import axios from 'axios';

// process.env.NODE_ENV === 'production' 来判断是否开发环境
// axios.defaults.baseURL = 'http://127.0.0.1:8000/' //本地。
axios.defaults.baseURL = 'https://wbyyrj.yiquhd.com/' //本地。
// 192.168.1.100


// 请求拦截器，请求发出之前的操作
axios.interceptors.request.use(
    config => {
        if (config.method == "get") config.params = config.data
		if (localStorage.getItem("token")){
			console.log(localStorage.getItem("token"))
			config.headers.Authorization = localStorage.getItem("token")
		}		
        return config;
    },
    error => {
        console.log("错误1", error);
        return Promise.reject();
    }
);

// 响应拦截器，请求回复后的操作
axios.interceptors.response.use(
    response => {
        console.log(response.config)
        // if (response.status != 200) return console.log("网络状态不是200，请求错误status")
            // if (response.data.status != 200) return console.log("接口状态不是200，请求错误data.status")
            // if (response.data.code != 200) return console.log("接口状态不是200，请求错误data.code")
        // if (response.data.code == 4101) return location.hash = '#/login'
            // 对于域名 跨域
        return response.data
    },
    error => {
        console.log("错误2", error);
        return Promise.reject();
    }
);

export const https = (method, url, data) => {
    return axios({ url, method, data });
}
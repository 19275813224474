import { https } from '../utils/request.js';



//获取数据
export const GetOrders = data => https("get", `/api/order/order`, data)

export const UpdateRemrk = data => https("post", `/api/order/update_ht`, data)


//获取渠道用户
export const getCpaChannels= data => https("get", `/api/user/cpa_channels`, data)
//新增渠道
export const addCpaChannels= data => https("post", `/api/user/cpa_channels`, data)
//修改

export const updateCpaChannels= data => https("post", `/api/user/update_cpa_channels`, data)
//update_cpa_status
export const updateCpaStatus= data => https("post", `/api/user/update_cpa_status`, data)
//
export const TotalDatas= data => https("post", `/api/user/total_datas`, data)

//獲取紀錄
export const getCpaRecords = data => https("get", `/api/user/cpa_records`, data)
//U换算
export const UConversion = data => https("post", `/api/user/update_records`, data)

export const Updatesettlement = data => https("post", `/api/user/settlement`, data)

//删除
export const DeleteChannel = data => https("post", `/api/user/delete_channel`, data)
//删除选中

export const DeleteALLChannel = data => https("post", `/api/user/delete_all_channel`, data)


